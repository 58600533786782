import { graphql, PageProps } from 'gatsby';
import { Box } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { SanityPage } from '../../graphql-types';
import Content from '../components/Content';
import useLocale from '../lib/useLocale';
import PageHeader from '../components/PageHeader';

interface PointOfSalePageProps extends PageProps {
    data: {
        page: SanityPage;
    };
}

export default function PointOfSalePage({ data }: PointOfSalePageProps) {
    const { translate } = useLocale();
    return (
        <>
            <SEO
                title={
                    data.page.title
                        ? translate(data.page.title)
                        : 'Puntos de venta'
                }
            />
            {data.page.title && <PageHeader title={data.page.title} />}
            <Box py={20}>
                <Content data={data.page.content} />
            </Box>
        </>
    );
}
export const query = graphql`
    query PointOfSalePageQuery($language: String!) {
        page: sanityPage(slug: { current: { eq: "puntos-de-venta" } }) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
